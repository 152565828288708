import React, { useState, useEffect } from "react";
import { db, storage } from "../config/Firebase"; // Import Firebase storage
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase storage methods
import AddProduct from "../components/AddProduct";
import "../styles/adminDashboard.css";

const AdminDashboard = () => {
  const [products, setProducts] = useState([]);
  const [editingProductId, setEditingProductId] = useState(null);
  const [updatedProduct, setUpdatedProduct] = useState({
    name: "",
    imageURL: "",
    description: "",
    category: [],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [imageFile, setImageFile] = useState(null); // New state for file uploads

  const productsCollectionRef = collection(db, "products");

  // Fetch products on component mount
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getDocs(productsCollectionRef);
        setProducts(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, []);

  // Handle image upload
  const handleImageUpload = async () => {
    if (!imageFile) return null;
    const imageRef = ref(storage, `products/${imageFile.name}`);
    try {
      const snapshot = await uploadBytes(imageRef, imageFile);
      return await getDownloadURL(snapshot.ref); // Get image URL after upload
    } catch (error) {
      console.error("Error uploading image: ", error);
      return null;
    }
  };

  // Update a product
  const updateProduct = async (e) => {
    e.preventDefault();
    try {
      const imageURL = imageFile ? await handleImageUpload() : updatedProduct.imageURL;
      const productDoc = doc(db, "products", editingProductId);
      await updateDoc(productDoc, { ...updatedProduct, imageURL });
      alert("Product updated successfully!");

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === editingProductId
            ? { ...product, ...updatedProduct, imageURL }
            : product
        )
      );
      setEditingProductId(null);
      setImageFile(null); // Reset image file
    } catch (error) {
      console.error("Error updating product: ", error);
      alert("Error updating product: " + error.message);
    }
  };

  // Delete a product
  const deleteProduct = async (productId) => {
    try {
      const productDoc = doc(db, "products", productId);
      await deleteDoc(productDoc);
      alert("Product deleted successfully!");
      const data = await getDocs(productsCollectionRef);
      setProducts(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error deleting product: ", error);
    }
  };

  // Start editing a product
  const startEditing = (product) => {
    setEditingProductId(product.id);
    setUpdatedProduct({
      name: product.name,
      imageURL: product.imageURL,
      description: product.description,
      category: product.category || [],
    });
  };

  // Filter products by search term
  const filteredProducts = products.filter((product) => {
    const productName = product.name ? product.name.toLowerCase() : "";
    const searchQuery = searchTerm ? searchTerm.toLowerCase() : "";
    if (!searchQuery) return true;
    return productName.includes(searchQuery);
  });

  return (
    <div className="admin-dashboard">
      <h2 className="dashboard-title">Admin Dashboard</h2>
      <AddProduct setProducts={setProducts} /> {/* Render AddProduct component */}
      <h2 style={{ textAlign: "center" }}>
        Collection List, Search the collection by its name
      </h2>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
      <div className="product-list">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <div key={product.id} className="product-item">
              {editingProductId === product.id ? (
                <form onSubmit={updateProduct} className="product-update-form">
                  <input
                    type="text"
                    value={updatedProduct.name}
                    onChange={(e) =>
                      setUpdatedProduct({ ...updatedProduct, name: e.target.value })
                    }
                    required
                    className="form-input"
                  />
                  <input
                    type="text"
                    value={updatedProduct.description}
                    onChange={(e) =>
                      setUpdatedProduct({ ...updatedProduct, description: e.target.value })
                    }
                    required
                    className="form-input"
                  />
                  <input
                    type="file"
                    onChange={(e) => setImageFile(e.target.files[0])}
                    className="form-input"
                  />
                  {updatedProduct.imageURL && (
                    <img src={updatedProduct.imageURL} alt="Preview" className="image-preview" />
                  )}
                  <input
                    type="text"
                    value={updatedProduct.category}
                    onChange={(e) =>
                      setUpdatedProduct({ ...updatedProduct, category: e.target.value.split(",") })
                    }
                    required
                    className="form-input"
                  />
                  <button type="submit" className="form-button">Update Collection</button>
                  <button
                    type="button"
                    onClick={() => setEditingProductId(null)}
                    className="form-button cancel-button"
                  >
                    Cancel
                  </button>
                </form>
              ) : (
                <div className="product-box">
                  <h3>{product.name}</h3>
                  {product.imageURL && <img src={product.imageURL} alt={product.name} className="product-image" />}
                  <p>
                    Created At: {product.createdAt?.toDate().toLocaleString() || "N/A"}
                  </p>

                  <div className="product-actions">
                    <button onClick={() => deleteProduct(product.id)} className="action-button">
                      Delete
                    </button>
                    <button onClick={() => startEditing(product)} className="action-button">
                      Update
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No collection available.</p>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
