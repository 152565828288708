import React from 'react';
import '../styles/menipedi.css'; // Ensure to include the CSS file

const meniPediServices = [
  {
    name: "Classic Manicure",
    description: "A classic manicure that includes nail shaping, cuticle care, and polish.",
  },
  {
    name: "Classic Pedicure",
    description: "Relaxing foot treatment with nail care, scrub, and polish.",
  },
  {
    name: "Gel Manicure",
    description: "Long-lasting gel polish applied with a meticulous manicure.",
  },
  {
    name: "Gel Pedicure",
    description: "Durable gel polish for feet with a pampering pedicure experience.",
  },
  {
    name: "Spa Manicure",
    description: "An indulgent manicure with a soothing hand massage and hydrating mask.",
  },
  {
    name: "Spa Pedicure",
    description: "Luxurious pedicure including massage, mask, and polish.",
  },
];

const MeniPedi = () => {
  return (
    <div className="menipedi-container">
      <h1 className="menipedi-title">Meni-Pedi Services</h1>
      <p className="menipedi-intro">
        Treat your hands and feet with our luxurious Meni-Pedi services. 
        Enjoy a relaxing experience that rejuvenates and beautifies.
      </p>
      <div className="menipedi-services">
        {meniPediServices.map((service, idx) => (
          <div key={idx} className="menipedi-service-card">
            <h2 className="service-name">{service.name}</h2>
            <p className="service-description">{service.description}</p>
            <p className="service-price">{service.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MeniPedi;
