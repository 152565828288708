import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Home from '../pages/Home';
import AdminLogin from '../auth/AdminLogin';
import AdminDashboard from '../auth/AdminDashboard';
import Facial from '../pages/Facial';
import Waxing from '../pages/Waxing';
import Threading from '../pages/Threading';
import MeniPedi from '../pages/MeniPedi';
import MakeUp from '../pages/MakeUp';
import HairService from '../pages/HairService';
import NailService from '../pages/NailService';
import Footer from '../pages/Footer';
import About from '../pages/About';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Contact from '../pages/Contact';
import AllProducts from '../pages/AllProducts';
import ProductDetails from '../pages/ProductDetails';
import TopRated from '../components/TopRated';
import Support from '../pages/Support';




const Routing = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
     
        <Route path="/" element={<Home />} />
        <Route path="/facial" element={<Facial />} />
        <Route path="/waxing" element={<Waxing />} />
        <Route path="/threading" element={<Threading />} />
        <Route path="/meni-pedi" element={<MeniPedi />} />
        <Route path="/makeup" element={<MakeUp />} />
        <Route path="/hair-service" element={<HairService />} />
        <Route path="/nail-service" element={<NailService/>} />
        <Route path="/footer" element={<Footer/>} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<Contact/>} />
        <Route path="/admin-login" element={<AdminLogin />} /> 
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/all-collection" element={<AllProducts />} />
        <Route path="/collection-details/:id" element={<ProductDetails />} />
        
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      
        </Routes>
     
    
    </Router>
  );
};

export default Routing;
