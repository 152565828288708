import React from 'react';
import '../styles/threading.css'; // Ensure to include the CSS file

const threadingServices = [
  {
    name: "Eyebrow Threading",
    description: "Precision threading to shape and define your eyebrows.",
  },
  {
    name: "Upper Lip Threading",
    description: "Gentle hair removal for the upper lip area.",
  },
  {
    name: "Chin Threading",
    description: "Effective threading service for the chin.",
  },
  {
    name: "Forehead Threading",
    description: "Smoothens the forehead by removing unwanted hair.",
  },
  {
    name: "Full Face Threading",
    description: "Complete threading service for the entire face.",
  },
  {
    name: "Sideburns Threading",
    description: "Removes hair along the sideburns for a clean look.",
  },
];

const Threading = () => {
  return (
    <div className="threading-container">
      <h1 className="threading-title">Threading Services</h1>
      <p className="threading-intro">
        Experience our professional threading services, designed to provide you with perfectly shaped features 
        and a clean look. Our experts ensure a gentle and effective process.
      </p>
      <div className="threading-services">
        {threadingServices.map((service, idx) => (
          <div key={idx} className="threading-service-card">
            <h2 className="service-name">{service.name}</h2>
            <p className="service-description">{service.description}</p>
            <p className="service-price">{service.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Threading;
