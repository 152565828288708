import React, { useState, useEffect } from 'react';
import { firestore, collection, addDoc, getDocs } from '../config/Firebase';
import { FaStar, FaRegStar } from 'react-icons/fa'; // Import the icons
import '../styles/rating.css'; // Import external CSS for styling

const Rating = ({ productId }) => {
  const [rating, setRating] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const [totalRatings, setTotalRatings] = useState(0); // Track total ratings

  // Function to fetch average rating and total ratings count for a specific product
  const fetchAverageRating = async () => {
    try {
      const snapshot = await getDocs(collection(firestore, 'ratings'));
      const ratings = snapshot.docs.map((doc) => doc.data());

      // Filter ratings for the current product
      const productRatings = ratings.filter((rating) => rating.productId === productId);
      const total = productRatings.length;

      if (total > 0) {
        const sum = productRatings.reduce((acc, doc) => acc + doc.rating, 0);
        const average = (sum / total).toFixed(1); // Calculate average
        setAverageRating(average); // Set average rating state
      } else {
        setAverageRating(0); // Reset average rating if no ratings
      }

      setTotalRatings(total); // Set total ratings count
    } catch (error) {
      console.error('Error fetching average rating:', error);
    }
  };

  // Fetch average rating and total ratings count on component mount or when productId changes
  useEffect(() => {
    fetchAverageRating(); // Call the fetch function
  }, [productId]); // Run only when the productId changes

  const handleSubmit = async () => {
    if (rating < 1 || rating > 5) {
      alert('Please select a rating between 1 and 5.');
      return; // Prevent submission if rating is out of bounds
    }

    try {
      await addDoc(collection(firestore, 'ratings'), {
        rating,
        productId,
        timestamp: new Date(), // Store current timestamp
      });
      setRating(0); // Reset rating after submission
      setSubmitted(true);
      fetchAverageRating(); // Refresh average rating and total count after submission
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  return (
    <div className="rate-me-container">
      <h2 className="text-xl font-bold">Rate this Product</h2>
      <div className="star-rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={star <= rating ? 'filled' : 'empty'}
            onClick={() => setRating(star)} // Update rating when star is clicked
          >
            {star <= rating ? <FaStar /> : <FaRegStar />} {/* Render filled or empty star */}
          </span>
        ))}
      </div>
      <p className="numeric-rating">{rating > 0 ? `Your Rating: ${rating}` : ''}</p> {/* Show numeric rating */}
      <button
        className="submit-button"
        onClick={handleSubmit}
        disabled={submitted}
      >
        {submitted ? 'Rating Submitted' : 'Submit Rating'}
      </button>
      {averageRating > 0 && (
        <p className="average-rating">
          Average Rating: {averageRating} ({totalRatings} ratings)
        </p> // Display average rating and total ratings count
      )}
    </div>
  );
};

export default Rating;
