import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/Firebase"; // Assuming you have Firebase initialized here
import { useNavigate } from "react-router-dom";
import StarRating from "../components/StarRating";
import "../styles/allProducts.css";
import Rating from "../components/Rating";
import Feedback from "../components/Feedback";

const AllProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleCount, setVisibleCount] = useState(5); // Count of visible products
  const [searchTerm, setSearchTerm] = useState(""); // Search input
  const [selectedCategories, setSelectedCategories] = useState([]); // Filtered categories
  const [loadingMore, setLoadingMore] = useState(false); // Loading state for the "Load More" button

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecentProducts = async () => {
      try {
        setLoading(true);
        const querySnapshot = await getDocs(collection(db, "products"));
        const productsArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort products by 'createdAt' date in descending order (most recent first)
        const sortedProducts = productsArray.sort((a, b) => {
          const dateA = a.createdAt ? a.createdAt.seconds : 0;
          const dateB = b.createdAt ? b.createdAt.seconds : 0;
          return dateB - dateA;
        });

        setProducts(sortedProducts);
      } catch (error) {
        console.error("Error fetching recent collections:", error);
        setError("Failed to fetch recent collections.");
      } finally {
        setLoading(false);
      }
    };

    fetchRecentProducts();
  }, []);

  const handleViewDetails = (productId) => {
    navigate(`/collection-details/${productId}`);
  };

  const loadMore = () => {
    setLoadingMore(true); // Set loading state for loading more products
    setVisibleCount((prevCount) => prevCount + 5); // Increase the count of visible products
    setLoadingMore(false); // Reset loading state after loading more products
  };

  // Search and Filter Logic
  const filteredProducts = products.filter((product) => {
    const matchesSearchTerm = product.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCategory =
      selectedCategories.length === 0 ||
      selectedCategories.includes(product.category);

    return matchesSearchTerm && matchesCategory;
  });

  return (
    <div className="all-products-container">
      <h2 style={{ textAlign: "center" }}>Most Recent Collection</h2>
      <header>
        <div className="filter-container">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <div className="categories">
            {[
              "Facial",
              "MakeUp",
              "HairService",
              "MeniPedi",
              "NailService",
              "Threading",
              "Waxing",
            ].map((category) => (
              <button
                key={category}
                onClick={() =>
                  setSelectedCategories((prev) =>
                    prev.includes(category)
                      ? prev.filter((cat) => cat !== category)
                      : [...prev, category]
                  )
                }
                className={`category-btn ${
                  selectedCategories.includes(category) ? "active" : ""
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      </header>

      {loading && <div className="loading">Loading recent collection...</div>}
      {error && <div className="error">{error}</div>}

      <div className="products-grid">
        {filteredProducts.length === 0 ? (
          <div className="no-products">No recent collection found.</div>
        ) : (
          filteredProducts.slice(0, visibleCount).map((product) => (
            <div
              key={product.id}
              className="product-item"
              onClick={() => handleViewDetails(product.id)}
            >
              <img
                className="product-image"
                src={product.imageURL}
                alt={product.name}
              />
              <div className="product-info">
                <h3 className="product-name">{product.name}</h3>
                <StarRating rating={product.averageRating || 0} />
              </div>
            </div>
          ))
        )}
      </div>

      {filteredProducts.length > visibleCount && (
        <button
          onClick={loadMore}
          className="load-more-button"
          disabled={loadingMore}
        >
          {loadingMore ? "Loading..." : "Load More"}
        </button>
      )}
    </div>
  );
};

export default AllProducts;
