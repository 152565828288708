import React from "react";
import "../styles/waxing.css"; // Ensure to include the CSS file

const waxingServices = [
  {
    name: "Full Body Waxing",
    description: "Complete waxing service for the entire body.",
  },
  {
    name: "Leg Waxing",
    description: "Removes hair from the knees to the toes.",
  },
  {
    name: "Arm Waxing",
    description: "Includes upper and lower arm waxing.",
  },

  {
    name: "Facial Waxing",
    description: "Waxing service for the eyebrows, lip, and chin.",
  },
  {
    name: "Underarm Waxing",
    description: "Quick and effective underarm waxing.",
  },
  {
    name: "Back Waxing",
    description: "Smoothens the back by removing unwanted hair.",
  },
];

const Waxing = () => {
  return (
    <div className="waxing-container">
      <h1 className="waxing-title">Waxing Services</h1>
      <p className="waxing-intro">
        Experience our professional waxing services designed to give you smooth
        and beautiful skin. Each service is tailored to meet your needs and
        preferences.
      </p>
      <div className="waxing-services">
        {waxingServices.map((service, idx) => (
          <div key={idx} className="waxing-service-card">
            <h2 className="service-name">{service.name}</h2>
            <p className="service-description">{service.description}</p>
            <p className="service-price">{service.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Waxing;
