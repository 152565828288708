import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/Firebase";
import Spinner from "../components/Spinner";
import "../styles/productDetails.css";
import Rating from "../components/Rating";
import Feedback from "../components/Feedback";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productDoc = await getDoc(doc(db, "products", id));
        if (productDoc.exists()) {
          setProduct(productDoc.data());
        } else {
          console.error("No such product!");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  // Show loading spinner if data is being fetched
  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="">
      {product ? (
        <div className="">
          <h2 style={{ textAlign: "center" }}>{product.name}</h2>

          <img
            src={product.imageURL}
            alt={product.name}
            className="product-image"
          />

          <p className="product-description">{product.description}</p>

          {/* Comments Section */}
          <div className="comments-section">
            <Rating rating={product.rating || 0} />
            <Feedback />

            {showComments && (
              <div className="comments-dropdown">
                {product.comments && product.comments.length > 0 ? (
                  product.comments.map((comment, index) => (
                    <div key={index}>
                      <p>{comment.comment}</p>
                    </div>
                  ))
                ) : (
                  <p>No comments yet.</p>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <p>Product not found!</p>
      )}
    </div>
  );
};

export default ProductDetails;
