import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../config/Firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for the toasts
import '../styles/contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    address: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add a new document with the form data
      await addDoc(collection(db, 'users'), {
        name: formData.name,
        phone: formData.phone,
        address: formData.address
      });

      // Success toast message
      toast.success('User data submitted successfully!', {
        position: toast.POSITION.TOP_CENTER
      });
      setFormData({ name: '', phone: '', address: '' }); // Clear form
    } catch (error) {
      // Error toast message
      toast.error('Error submitting data. Please try again.', {
        position: toast.POSITION.TOP_CENTER
      });
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div className="contact-form-container">
      <h2>Contact Information Form</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Phone Number:</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>

      <section>
        <h3>Or Contact Me on WhatsApp</h3>
        <a
          href="https://wa.me/9136634206"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          <h5>Lalita Nighot</h5>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
            alt="WhatsApp"
            className="icon"
          />
          <span className="phoneNumber">9987574129</span>
        </a>
      </section>

      {/* Toast Container for notifications */}
      <ToastContainer />
    </div>
  );
};

export default Contact;
          