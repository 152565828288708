import React, { useState } from "react";
import { db, storage } from "../config/Firebase"; // Import Firebase Firestore and Storage
import { addDoc, collection, serverTimestamp } from "firebase/firestore"; // Firebase Firestore methods
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Firebase Storage methods
import { toast } from "react-toastify";

const AddProduct = ({ setProducts }) => {
  const [newProduct, setNewProduct] = useState({
    name: "",
    imageURL: "", // This will hold the URL of the image
    description: "",
    category: [],
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [imageFile, setImageFile] = useState(null); // New state for the image file

  const categoryOptions = [
    "Facial", "HairService", "MakeUp", "MeniPedi", "NailService", "Threading", "Waxing",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleCategoryChange = (e) => {
    const options = Array.from(e.target.selectedOptions, (option) => option.value);
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      category: options,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  const addProduct = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!newProduct.name.trim()) {
      setErrorMessage("Collection name cannot be empty.");
      return;
    }
    if (newProduct.category.length === 0) {
      setErrorMessage("Please select at least one category.");
      return;
    }
    if (!imageFile) {
      setErrorMessage("Please upload an image.");
      return;
    }

    setLoading(true);
    setErrorMessage(""); // Clear any previous errors

    try {
      // Upload image to Firebase Storage
      const storageRef = ref(storage, `product-images/${imageFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      uploadTask.on(
        "state_changed",
        null, // You can add progress handling here
        (error) => {
          setErrorMessage("Image upload failed. Please try again.");
          toast.error("Image upload failed.");
        },
        async () => {
          // Get download URL once the image is uploaded
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Add the product to Firestore with the image URL
          const productsCollectionRef = collection(db, "products");
          await addDoc(productsCollectionRef, {
            ...newProduct,
            imageURL: downloadURL, // Use the download URL from Firebase Storage
            createdAt: serverTimestamp(),
          });

          setProducts((prevProducts) => [
            ...prevProducts,
            { ...newProduct, imageURL: downloadURL },
          ]);

          toast.success("Product added successfully!");

          // Clear the form
          setNewProduct({
            name: "",
            imageURL: "",
            description: "",
            category: [],
          });
          setImageFile(null);
        }
      );
    } catch (error) {
      console.error("Error adding product:", error);
      setErrorMessage("Failed to add product. Please try again.");
      toast.error("Failed to add product.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={addProduct} className="product-form">
      <input
        type="text"
        name="name"
        placeholder="Collection Name"
        value={newProduct.name}
        onChange={handleInputChange}
        required
        className="form-input"
      />
      {/* Image File input */}
      <input
        type="file"
        name="imageFile"
        onChange={handleImageChange}
        required
        className="form-input"
      />
      <textarea
        name="description"
        placeholder="Description"
        value={newProduct.description}
        onChange={handleInputChange}
        required
        className="form-textarea"
      ></textarea>
      <select
        multiple
        name="category"
        value={newProduct.category}
        onChange={handleCategoryChange}
        required
        className="form-input"
      >
        {categoryOptions.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </select>

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <button type="submit" className="form-button" disabled={loading}>
        {loading ? "Adding..." : "Add Collection"}
      </button>
    </form>
  );
};

export default AddProduct;
