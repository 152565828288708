import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, collection, getDocs, deleteDoc, addDoc, updateDoc } from 'firebase/firestore';
//import { getAnalytics } from 'firebase/analytics';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDHqLf0ZWg6opRgMgqB7FbvP_4xzoJphR0",
  authDomain: "business-world-9632a.firebaseapp.com",
  projectId: "business-world-9632a",
  storageBucket: "business-world-9632a.appspot.com",
  messagingSenderId: "334235376571",
  appId: "1:334235376571:web:6fd4464335b92938ea429e"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and Firestore
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// Send password reset email
const email = "tysonxwort@outlook.com";  // Replace with actual email
sendPasswordResetEmail(auth, email)
  .then(() => {
    console.log("Password reset email sent!");
  })
  .catch((error) => {
    console.error("Error sending password reset email:", error);
  });

export { auth, functions, firestore, storage, db, updateDoc, createUserWithEmailAndPassword, signInWithEmailAndPassword, doc, setDoc, getDoc, addDoc, collection, getDocs, ref, uploadBytes, deleteDoc, getDownloadURL };

